<template>
  <div class="container person" v-loading="loading">
    <div class="top">
      <div class="name_wrapper">
        <div class="name">{{ details.name }}</div>
        <div>
          最近中标时间：
          {{ recentTime }}
        </div>
      </div>

      <el-row :gutter="20" class="mb20">
        <el-col :span="12">
          <div class="flex alc">
            <div class="label">证件类型</div>
            <div class="value">{{ details.cardType | noVoid }}</div>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="flex alc">
            <div class="label">证件号码</div>
            <div class="value">{{ details.idNo | noVoid }}</div>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <div class="flex alc">
            <div class="label">性别</div>
            <div class="value">{{ details.sex | noVoid }}</div>
          </div>
        </el-col>

        <el-col :span="12">
          <div class="flex alc">
            <div class="label">证书注册所在单位名称</div>
            <div class="value">
              {{ details.companyName | noVoid }}
            </div>
          </div>
        </el-col>
      </el-row>
    </div>

    <div class="public_title">注册信息</div>
    <div>
      <div class="list">
        <div class="item mb20" v-for="(item, index) in personnelPro">
          <div class="name_warpper">
            <div>
              {{ `${index + 1}、` }}
            </div>
            <div>
              {{ item.regType | noVoid }}
            </div>
          </div>

          <el-descriptions title="" :column="3">
            <el-descriptions-item label="身份证号">
              {{ item.idNo | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="注册类别">
              {{ item.regType | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="注册号（执业印章号）">
              {{ item.regNo3 | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="注册专业">
              {{ item.regMajor | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="有效期">
              {{ item.regTerm | noVoid }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <el-empty
        v-if="personnelPro.length == 0"
        description="暂无数据"
      ></el-empty>
    </div>

    <div class="public_title">项目经理业绩</div>
    <div>
      <div class="list">
        <div class="item mb20" v-for="(item, index) in managerResultList">
          <div class="name_warpper link" @click="toDetails(item.id)">
            <div>
              {{ `${index + 1}、` }}
            </div>
            <div>
              {{ item.name | noVoid }}
            </div>
          </div>

          <el-descriptions title="" :column="2">
            <el-descriptions-item label="项目金额">
              {{ item.money }}
            </el-descriptions-item>

            <el-descriptions-item label="项目地址">
              {{ item.place | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="项目时间">
              {{ item.time | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="建设单位">
              {{ item.owner | noVoid }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <el-empty
        v-if="managerResultList.length == 0"
        description="暂无数据"
      ></el-empty>
    </div>

    <div class="public_title">技术负责人业绩</div>
    <div>
      <div class="list">
        <div class="item mb20" v-for="(item, index) in technicianResultList">
          <div class="name_warpper link" @click="toDetails(item.id)">
            <div>
              {{ `${index + 1}、` }}
            </div>
            <div>
              {{ item.name | noVoid }}
            </div>
          </div>

          <el-descriptions title="" :column="2">
            <el-descriptions-item label="项目金额">
              {{ item.money }}
            </el-descriptions-item>

            <el-descriptions-item label="项目地址">
              {{ item.place | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="项目时间">
              {{ item.time | noVoid }}
            </el-descriptions-item>

            <el-descriptions-item label="建设单位">
              {{ item.owner | noVoid }}
            </el-descriptions-item>
          </el-descriptions>
        </div>
      </div>

      <el-empty
        v-if="technicianResultList.length == 0"
        description="暂无数据"
      ></el-empty>
    </div>

    <!-- <el-tabs type="card">
      <el-tab-pane label="注册信息"> </el-tab-pane>

      <el-tab-pane label="项目经理业绩"> </el-tab-pane>

      <el-tab-pane label="技术负责人业绩"> </el-tab-pane>
    </el-tabs> -->
  </div>
</template>

<script>
/* 人员详情 */
import { searchPersonAllByName } from "@/api/detailPage.js";

export default {
  data() {
    return {
      companyId: "",
      name: "",
      details: {},
      personnelPro: [],
      managerResultList: [],
      technicianResultList: [],
      recentTime: "",
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取详情数据
    async getData() {
      this.loading = true;
      const params = {
        companyId: this.companyId,
        name: this.name,
      };
      const { code, data } = await searchPersonAllByName(params);
      if (code == 0) {
        const { personnelPro, managerDto } = data;
        const { managerResultList, technicianResultList } = managerDto;
        const details = personnelPro[0];
        this.details = details;
        this.personnelPro = personnelPro;
        this.managerResultList = this.managerResultList.concat(
          this.processingListdata(managerResultList)
        );
        this.technicianResultList = this.technicianResultList.concat(
          this.processingListdata(technicianResultList)
        );
      }
      this.loading = false;
    },
    // 处理list数据
    processingListdata(arr) {
      arr.forEach((v) => {
        if (v.amtUnit == "%" || v.amtUnit == "-1" || v.amount == 0) {
          v.amount = "-";
          v.amtUnit = "-";
        } else if (v.amtUnit == "元") {
          v.amount = (v.amount / 10000).toFixed(2);
          v.amtUnit = "万";
        } else {
          v.amount = v.amount.toFixed(2);
          v.amtUnit = "万";
        }
        v["money"] = v.amount + v.amtUnit;
      });
      return arr;
    },

    /* 操作 */
    // 跳转到详情页面
    toDetails(id) {
      this.$router.push({ name: "performanceDetail", params: { id } });
    },

    /* 初始化 */
    __init__() {
      this.companyId = this.$route.params.id;
      this.name = this.$route.query.name;
      this.recentTime = this.$route.query.time;
      this.getData();
    },
  },

  created() {
    this.__init__();
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.person {
  padding: 20px;
  background-color: #fff;

  .top {
    color: #333;
    font-size: 14px;
    margin-bottom: 20px;

    .name_wrapper {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 20px;

      .name {
        font-size: 20px;
        font-weight: bold;
      }
    }

    .label {
      margin-right: 20px;
      color: #999;
    }
  }

  .name_warpper {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    // font-weight: bold;
    font-size: 16rpx;
    margin-bottom: 20rpx;
    color: #333;

    .name {
      color: #c63f1a;
    }
  }

  /deep/.el-descriptions__body {
    padding: 20px;
    background-color: #f5faff;
  }
}
</style>
