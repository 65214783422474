// 分项查询模块
import request from '@/utils/request'// 引入设置好的axios

// post请求示例
/* export const addGoodsCate = (data) => {
    return request({
        url: '/goods/addcate',
        method: "post",
        data
    })
} */

// get请求示例
/* export const getCateList = (params) => {
    return request({
        url: '/goods/catelist',
        method: "get",
        params
    })
} */

// 获取推荐公司数据-全部
export const getAllCompanyList = (params) => {
  return request({
    url: '/api/company/allCompanyRemarkIsTrue',
    method: "get",
    params
  })
}

// 获取推荐公司数据-加盟
export const getAlignList = (params) => {
  return request({
    url: '/api/company/jiamengCompanyRemarkIsTrue',
    method: "get",
    params
  })
}

// 查询最新信息
export const searchRecent = (params) => {
  return request({
    url: '/api/result/searchRecentByLocation',
    method: "get",
    params
  })
}

// 保存企业入驻申请
export const companyInSave = (data) => {
  return request({
    url: '/api/companyIn/save',
    method: "post",
    data
  })
}

// 获取企业入驻列表
export const getCompanyInList = (params) => {
  return request({
    url: '/api/companyIn/getByUserId',
    method: "get",
    params
  })
}