<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业名称">
            <el-input
              v-model="form.name"
              placeholder="请输入企业名称关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="成立时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="注册资金">
            <div class="flex alc">
              <el-input
                v-model.number="form.regCostMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.regCostMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="实缴资本">
            <div class="flex alc">
              <el-input
                v-model.number="form.paidupCapitalMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.paidupCapitalMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <div v-if="showmore"> -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="企业地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="企业法人">
            <el-input
              v-model="form.legalPerson"
              placeholder="请输入企业法人姓名"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="经营范围">
            <el-input
              v-model="form.businessScope"
              placeholder="支持多关键词，如：公路 道路"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="注册地址">
            <el-input
              v-model="form.regAddress"
              placeholder="请输入企业地址关键字，如：开发区"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="合作方式">
            <el-radio-group v-model="form.joinIn">
              <el-radio label="全部">全部</el-radio>
              <el-radio label="加盟分公司"> 加盟分公司 </el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="是否推荐">
            <el-radio-group v-model="form.rmd">
              <el-radio label="全部">全部</el-radio>
              <el-radio label="是">是</el-radio>
            </el-radio-group>
          </el-form-item>
        </el-col>
      </el-row>
      <!-- </div> -->

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>

          <!-- <el-button @click="showmore = !showmore">
            {{ this.showmore ? "收起" : "更多" }}
          </el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          家符合的企业
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name">
            <span class="link" @click="toDetail(item.id)">
              {{ item.name | noVoid }}
            </span>

            <span v-if="item.type" class="tag">{{ item.type }}</span>
          </div>

          <div class="desc">
            <div class="desc_item">
              注册资金：
              {{ item.regCost | noVoid }}
            </div>

            <div class="desc_item">
              业绩数量：
              {{ item.resultNum || 0 }}
            </div>

            <div class="desc_item">
              注册地区：
              {{
                `${item.province || "-"}${
                  !item.city || item.city === "-"
                    ? !item.district || item.district == "-"
                      ? "-"
                      : item.district
                    : item.city
                }`
              }}
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分享查询-查企业 */
// 引入ajax函数
import { searchCompany } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },
  // 数据
  data() {
    return {
      form: {
        location: [], // 企业地区,
        joinIn: "全部", // 合作方式
        rmd: "全部", // 合作方式
        name: "", // 企业名称
        regCostMin: "", // 注册资金最小值
        regCostMax: "", // 注册资金最大值
        paidupCapitalMin: "", // 实缴资本最小值
        paidupCapitalMax: "", // 实缴资本最大值
        startDateStart: "", // 开始时间
        startDateEnd: "", // 结束时间
        legalPerson: "", // 企业法人
        // tag: [], // 企业标签
        businessScope: "", // 经营范围
        regAddress: "", // 注册地址
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      },
      areaOptions: [], // 地区数据

      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      time: [], // 成立时间
      queryTypeOptions: [], // 检索模式

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      const location = this.form.location.filter((v) => v != "").join("-");
      let startDateStart = "";
      let startDateEnd = "";
      if (this.time.length > 0) {
        startDateStart = this.time[0];
        startDateEnd = this.time[1];
      }
      let params = {
        ...this.form,
        location,
        startDateStart,
        startDateEnd,
        total: this.total,
      };

      let res = await searchCompany(params);
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({
        name: "companyDetails",
        params: { name: "资质等级" },
        query: { id },
      });
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 企业地区,
        joinIn: "全部", // 合作方式
        rmd: "全部", // 合作方式
        name: "", // 企业名称
        regCostMin: "", // 注册资金最小值
        regCostMax: "", // 注册资金最大值
        paidupCapitalMin: "", // 实缴资本最小值
        paidupCapitalMax: "", // 实缴资本最大值
        startDateStart: "", // 开始时间
        startDateEnd: "", // 结束时间
        legalPerson: "", // 企业法人
        // tag: [], // 企业标签
        businessScope: "", // 经营范围
        regAddress: "", // 注册地址
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查企业") {
        this.form.name = name;
      }

      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完成状态
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .list {
    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px solid #efefef;
      cursor: pointer;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;

        .tag {
          font-size: 14px;
          padding: 2px 8px 4px;
          border-radius: 4px;
          line-height: 22px;
          color: #1d89e3;
          background-color: #d7edfb;
        }
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          margin-right: 80px;
          flex: 1;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
