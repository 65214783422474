<template>
  <div class="company_info">
    <div class="name_wrapper">
      <div class="name">{{ details.name }}</div>
      <el-popover
        placement="bottom"
        title=""
        width="200"
        trigger="hover"
        :content="details.nameUsed"
      >
        <div class="cursorP tag_style" slot="reference">曾用名</div>
      </el-popover>
    </div>

    <div class="bgf5faff pd20 c666">
      <el-row :gutter="20" class="mb15">
        <el-col class="flex alc" :span="8">
          <div class="label">成立日期：</div>
          <div class="value singleLine">{{ details.establishmentTime }}</div>
        </el-col>

        <el-col class="flex alc" :span="8">
          <div class="label">注册资金：</div>
          <div class="value singleLine">{{ details.regCost }}</div>
        </el-col>

        <el-col class="flex alc" :span="8">
          <div class="label">实缴资金：</div>
          <div class="value singleLine">{{ details.paidupCapital }}</div>
        </el-col>
      </el-row>

      <el-row :gutter="20" class="mb15">
        <el-col class="flex alc" :span="8">
          <div class="label">法人代表：</div>
          <div class="value singleLine">{{ details.legalPerson }}</div>
        </el-col>

        <el-col class="flex alc" :span="8">
          <div class="label">联系电话：</div>
          <div class="value flex alc">
            <div class="singleLine wbbreakall">
              {{ details.contactPhone }}
            </div>
            <span
              v-if="details.contactPhone && details.contactPhone.length > 29"
              class="cursorP c409eff wsnowrap ml5"
              @click="contactShow = true"
            >
              更多
            </span>
            <span
              class="cursorP c409eff wsnowrap ml5"
              v-else
              @click="copyPhone(details.contactPhone)"
            >
              复制电话
            </span>
          </div>
        </el-col>

        <el-col class="flex alc" :span="8">
          <div class="label">注册地址：</div>
          <div class="value flex alc">
            <div class="singleLine">
              {{ details.regAddress }}
            </div>
            <span
              v-if="details.regAddress && details.regAddress.length > 17"
              class="cursorP c409eff wsnowrap"
              @click="addressShow = true"
            >
              更多
            </span>
          </div>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col class="flex" :span="24">
          <div class="label">经营范围：</div>
          <div class="value scope">
            <div class="singleLine">
              {{ details.businessScope }}
            </div>
            <span
              v-if="details.businessScope && details.businessScope.length > 70"
              class="cursorP c409eff"
              @click="scopeShow = true"
            >
              更多
            </span>
          </div>
        </el-col>
      </el-row>
    </div>

    <!-- 经营范围 -->
    <customDialog v-model="scopeShow" title="经营范围" width="30%">
      <div class="mask">
        <div style="line-height: 30px">
          {{ details.businessScope }}
        </div>

        <div class="tac mt20">
          <el-button type="primary" @click="scopeShow = false">关闭</el-button>
        </div>
      </div>
    </customDialog>

    <!-- 注册地址 -->
    <customDialog v-model="addressShow" title="注册地址" width="30%">
      <div class="mask">
        <div style="line-height: 30px">
          {{ details.regAddress }}
        </div>

        <div class="tac mt20">
          <el-button type="primary" @click="addressShow = false">
            关闭
          </el-button>
        </div>
      </div>
    </customDialog>

    <!-- 联系电话 -->
    <customDialog v-model="contactShow" title="联系电话" width="30%">
      <div class="mask">
        <div style="line-height: 30px" class="flex fww">
          <div
            v-for="(item, index) in details.contactPhoneList"
            :key="'phone' + index"
            class="flex alc mb10"
            style="width: 50%"
          >
            <div>
              {{ item }}
            </div>

            <i
              class="el-icon-copy-document ml10 cursorP"
              @click="copyPhone(item)"
            ></i>
          </div>
        </div>

        <div class="tac mt20">
          <el-button @click="contactShow = false"> 关闭 </el-button>
          <el-button type="primary" @click="copyPhone(details.contactPhone)">
            复制全部号码
          </el-button>
        </div>
      </div>
    </customDialog>
  </div>
</template>

<script>
/* 企业详情-顶部公司信息 */

export default {
  props: ["details"],

  data() {
    return {
      scopeShow: false,
      addressShow: false,
      contactShow: false,
    };
  },

  methods: {
    /* 操作 */
    // 复制文本
    async copyPhone(text) {
      try {
        await navigator.clipboard.writeText(text);
        this.$message.success("复制成功");
      } catch (err) {
        this.$message.error("复制失败,请重试！");
        console.error("复制到剪贴板失败:", err);
      }
    },
  },
};
</script>

<style lang="less" scoped>
.company_info {
  padding: 25px;
  background-color: #fff;
  margin-bottom: 20px;
  color: #333;
  font-size: 14px;

  .name_wrapper {
    display: flex;
    align-items: baseline;
    justify-content: space-between;
    margin-bottom: 20px;

    .name {
      font-size: 20px;
      font-weight: bold;
    }
  }

  .value {
    flex: 1;
  }

  .scope {
    display: flex;
    align-items: center;
    .singleLine {
      flex: 1;
    }
  }
}
</style>
