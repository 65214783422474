<template>
  <div class="home_banner">
    <div class="main">
      <div class="title">建筑业大数据服务平台</div>

      <div class="nav">
        <div
          :class="['nav_item', index == nav.activeIndex ? 'active' : '']"
          v-for="(item, index) in nav.list"
          :key="item.name"
          @click="nav.activeIndex = index"
        >
          {{ item.name }}
        </div>
      </div>

      <div class="search">
        <div class="input">
          <el-input placeholder="请输入内容" v-model.trim="keyWord">
            <template slot="append">
              <div class="cursorP" @click="handleSearch">查询一下</div>
            </template>
          </el-input>
        </div>

        <div class="combined_query_btn cursorP" @click="toCombinedQuery">
          组合查询
        </div>
      </div>

      <div class="quick_link" v-if="quickLink.length > 0">
        <div
          class="item"
          v-for="item in quickLink"
          :key="item.name"
          @click="toQuickLink(item)"
        >
          <img class="icon" :src="item.iconUrl" alt="" />
          <span>{{ item.name }}</span>
        </div>
      </div>

      <div class="desc">
        <div class="main_text">智链建安，AI速配——您的全方位工程合作平台</div>
        <div class="minor_text">
          一键查询，精准对接，让资质与需求无缝相连，立即入驻，开启智能合作之旅！
        </div>
      </div>

      <div class="try_btn" @click="applyTry">申请免费入驻</div>
    </div>
  </div>
</template>

<script>
/* 首页-banner-搜索 */
import local from "@/utils/local.js";

export default {
  data() {
    return {
      nav: {
        list: [
          { name: "建筑企业", path: "itemizedQuery", params: "查企业" },
          { name: "中标业绩", path: "itemizedQuery", params: "查业绩" },
          { name: "人员信息", path: "itemizedQuery", params: "查人员" },
          { name: "项目经理", path: "itemizedQuery", params: "项目经理" },
          { name: "资质等级", path: "itemizedQuery", params: "查资质" },
          { name: "荣誉信息", path: "itemizedQuery", params: "查荣誉" },
          { name: "诚信信息", path: "itemizedQuery", params: "查诚信" },
          { name: "信用评价", path: "itemizedQuery", params: "查评价" },
          { name: "招标记录", path: "itemizedQuery", params: "查招标" },
        ],
        activeIndex: 0,
      },

      quickLink: [
        // {
        //   name: "公路业绩",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/gonglu.png",
        //   path: "",
        // },
        // {
        //   name: "四库业绩",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/siku.png",
        //   path: "",
        // },
        // {
        //   name: "省库业绩",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/shengku.png",
        //   path: "",
        // },
        // {
        //   name: "资质帮学社",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/jianshe.png",
        //   path: "",
        // },
        // {
        //   name: "查招标",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/zhaobiao.png",
        //   path: "itemizedQuery",
        //   params: "查招标",
        // },
        // {
        //   name: "业主单位",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/yezhu.png",
        //   path: "",
        // },
        // {
        //   name: "建企500强榜单",
        //   iconUrl: "https://www.zizhihelp.com/upload/static/web/bangdan.png",
        //   path: "",
        // },
      ],

      keyWord: "",
    };
  },

  methods: {
    /* 操作 */
    // 查询一下
    handleSearch() {
      const item = this.nav.list[this.nav.activeIndex];
      const path = item.path;
      if (path) {
        this.$router.push({
          name: path,
          params: { name: item.params },
          query: { name: this.keyWord },
        });
      }
    },
    // 跳转组合查询
    toCombinedQuery() {
      this.$router.push({ name: "combinedQuery" });
    },
    // 跳转快捷链接
    toQuickLink(item) {
      const path = item.path;
      if (path) {
        this.$router.push({
          name: path,
          params: { name: item.params },
        });
      }
    },
    // 申请免费试用
    applyTry() {
      let userInfo = local.get("userInfo") || {};

      if (userInfo && userInfo.id) {
        this.$router.push("/settlement");
      } else {
        this.$message.warning("请先登录！");
        this.$router.push("/login");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.home_banner {
  background: url("https://www.zizhihelp.com/upload/static/web/beijing1.png")
    no-repeat center center / cover;
  padding: 50px 0px;

  .main {
    display: flex;
    flex-direction: column;
    align-items: center;
    color: #fff;
    font-size: 16px;

    .title {
      font-size: 50px;
      margin-bottom: 40px;
    }

    .nav {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      .nav_item {
        margin-right: 15px;
        line-height: 22px;
        padding: 3px 8px;
        cursor: pointer;
      }

      .active,
      .nav_item:hover {
        color: #1d89e3;
        background-color: #fff;
        border-radius: 4px;
        position: relative;

        &::after {
          content: "";
          position: absolute;
          bottom: -15px;
          left: 50%;
          transform: translateX(-50%);
          border-left: 8px solid transparent;
          border-right: 8px solid transparent;
          border-top: 8px solid #fff;
          border-bottom: 8px solid transparent;
        }
      }
    }

    .search {
      display: flex;
      align-items: center;
      margin-bottom: 36px;

      .input {
        width: 790px;
        height: 48px;

        /deep/.el-input {
          font-size: 18px;
          line-height: 46px !important;
        }

        /deep/.el-input__inner {
          height: 48px;
          line-height: 48px;
        }

        /deep/.el-input-group__append {
          background-color: #1d89e3;
          border-color: #1d89e3;
          color: #fff;
        }
      }

      .combined_query_btn {
        color: #fff;
        font-size: 18px;
        line-height: 28px;
        padding: 10px 15px;
        background-color: #ff8d20;
        border-radius: 4px;
        margin-left: 12px;
      }
    }

    .quick_link {
      display: flex;
      align-items: center;
      margin-bottom: 100px;

      .item {
        display: flex;
        flex-direction: column;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        margin-right: 70px;
        cursor: pointer;

        .icon {
          width: 40px;
          height: 40px;
          margin-bottom: 12px;
        }

        &:last-child {
          margin-right: 0px;
        }
      }
    }

    .desc {
      margin-bottom: 30px;
      text-align: center;
      .main_text {
        font-size: 32px;
        line-height: 45px;
        margin-bottom: 15px;
      }

      .minor_text {
        font-size: 20px;
        line-height: 28px;
      }
    }

    .try_btn {
      color: #1d89e3;
      line-height: 22px;
      padding: 10px 20px;
      background-color: #fff;
      border-radius: 4px;
      cursor: pointer;
      border: 1px solid #fff;

      &:hover {
        color: #fff;
        background-color: #409eff;
      }
    }
  }
}
</style>
