<template>
  <div class="container protocol_wrapper">
    <div v-html="protocolText"></div>
  </div>
</template>

<script>
import { getProtocol } from "@/api/login.js";

export default {
  data() {
    return {
      protocolText: "",
    };
  },

  methods: {
    async __init__() {
      let { code, data } = await getProtocol({ page: "appProtocol" });
      if (code == 0) {
        this.protocolText = data.content;
      }
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.protocol_wrapper {
  padding: 20px;
  background-color: #fff;
  line-height: 1.8;

  /deep/.ql-align-center {
    text-align: center !important;
  }
}
</style>
