<template>
  <div class="container performance" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.name }}</div>

      <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div>
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">中标单位</div>
        <div class="value">
          {{ details.winner | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">中标金额</div>
        <div class="value">
          {{ details.money }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目地址</div>
        <div class="value">
          {{ details.place | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">中标时间</div>
        <div class="value">
          {{ details.time | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">业绩类型</div>
        <div class="value">
          {{ details.type | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">工期</div>
        <div class="value">
          {{ details.duration | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目经理</div>
        <div class="value">
          {{ details.manager | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">技术负责人</div>
        <div class="value">
          {{ details.technician | noVoid }}
        </div>
      </div>

      <div class="table_cell" v-if="details.secure && details.secure !== '-1'">
        <div class="label">专职安全员</div>
        <div class="value">
          {{ details.secure }}
        </div>
      </div>

      <div
        class="table_cell"
        v-if="details.supervisor && details.supervisor !== '-1'"
      >
        <div class="label">监理负责人</div>
        <div class="value">
          {{ details.supervisor }}
        </div>
      </div>

      <div class="table_cell" v-if="details.survey && details.survey !== '-1'">
        <div class="label">勘察负责人</div>
        <div class="value">
          {{ details.survey }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">建设单位</div>
        <div class="value">
          {{ details.owner | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">中标公告</div>
        <div class="value">
          <div v-if="details.winningAnnouncement.length > 0">
            <span
              v-for="item in details.winningAnnouncement"
              class="vip"
              @click="linkToUrl(item.key)"
            >
              {{ item.value }}
            </span>
          </div>
          <span v-else>--</span>
        </div>
      </div>

      <div class="table_cell" v-if="details.tenderAnnouncement.length > 0">
        <div class="label">招标公告</div>
        <div class="value">
          <div>
            <span
              v-for="item in details.tenderAnnouncement"
              class="vip"
              @click="linkToUrl(item.key)"
            >
              {{ item.value }}
            </span>
          </div>
        </div>
      </div>

      <div class="table_cell" v-if="details.candidateAnnouncement.length > 0">
        <div class="label">中标候选人公告</div>
        <div class="value">
          <div>
            <span
              v-for="item in details.candidateAnnouncement"
              class="vip"
              @click="linkToUrl(item.key)"
            >
              {{ item.value }}
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 业绩详情 */
import { searchResult } from "@/api/itemizedQuery";

export default {
  data() {
    return {
      id: "", // id
      // 详情
      details: {
        winningAnnouncement: [], // 中标公告
        tenderAnnouncement: [], // 招标公告
        candidateAnnouncement: [], // 中标候选人公告
      },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取详情
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
        pageNum: 1,
        pageSize: 10,
      };
      const { records } = await searchResult(params);
      if (records.length > 0) {
        records.forEach((v) => {
          if (v.amtUnit == "%" || !v.amtUnit) {
            v.amount = "-";
            v.amtUnit = "-";
          } else if (v.amtUnit == "元") {
            v.amount = (v.amount / 10000).toFixed(2);
            v.amtUnit = "万";
          } else {
            v.amount = v.amount.toFixed(2);
            v.amtUnit = "万";
          }
          v["money"] = v.amount + v.amtUnit;

          if (v.time && v.time.includes(" ")) {
            v.time = v.time.split(" ")[0];
          }
          if (v.manager == "-1" || !v.manager) {
            v.manager = "--";
          }
        });
        this.details = records[0];
        this.loading = false;
      }
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.winnerId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "企业业绩" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.getData();
    },
  },

  created() {
    this.__init__(); // 初始化
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.performance {
  padding: 20px;
  background-color: #fff;

  .top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
  }

  .table {
    .table_cell {
      display: flex;
      align-items: stretch;
      border: 1px solid #ecf1ff;
      border-bottom: none;
      border-right: none;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid #ecf1ff;
      }

      .label {
        padding: 15px;
        min-width: 200px;
        text-align: center;
        color: #666;
        display: flex;
        align-items: center;
        background-color: #f5faff;
      }

      .value {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        color: #666;
        border-right: 1px solid #ecf1ff;
        border-left: 1px solid #ecf1ff;
        line-height: 1.5;
      }
    }
  }
}
</style>
