<template>
  <div class="container settlement_list">
    <el-button type="primary" icon="el-icon-plus" @click="add">新增</el-button>

    <el-form :model="form" class="mt20">
      <el-row :gutter="20">
        <el-col :span="8">
          <el-form-item label="审核状态" prop="auditStatus" label-width="80px">
            <el-select
              v-model="form.auditStatus"
              placeholder="请选择状态"
              @change="getData"
              style="width: 100%;"
            >
              <el-option
                v-for="item in statusOptions"
                :key="item.value"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>
    </el-form>

    <el-table
      :data="tableData"
      style="width: 100%; margin-top: 20px"
      border
      max-height="500px"
    >
      <el-table-column prop="companyName" label="企业名称">
        <template slot-scope="scope">
          <div class="link" @click="showDetail(scope.row)">
            {{ scope.row.companyName }}
          </div>
        </template>
      </el-table-column>
      <el-table-column prop="updateTime" label="提交时间"> </el-table-column>
      <el-table-column prop="auditStatus" label="审核状态"> </el-table-column>
    </el-table>

    <!-- 分页 -->
    <el-pagination
      class="pagination"
      @size-change="sizeChange"
      @current-change="currentChange"
      :current-page="form.pageNum"
      :page-sizes="[10, 20, 30, 40, 50, 100]"
      :page-size="form.pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>

    <customDialog v-model="detailShow" title="详情" width="40%">
      <el-form>
        <el-form-item label="企业名称">
          {{ details.companyName }}
        </el-form-item>

        <el-form-item label="营业执照">
          <img :src="details.businessLicenseUrl" class="avatar" />
        </el-form-item>

        <el-form-item label="真实姓名">
          {{ details.contractName }}
        </el-form-item>

        <el-form-item label="联系方式">
          {{ details.contractPhone }}
        </el-form-item>

        <el-form-item label="联系微信">
          {{ details.contractWx }}
        </el-form-item>

        <el-form-item label="合作方式">
          {{ details.cooperationType }}
        </el-form-item>

        <el-form-item label="可合作区域">
          {{ details.cooperativePlace }}
        </el-form-item>

        <el-form-item label="合作费用">
          {{ details.cooperativeCost }}
        </el-form-item>

        <el-form-item label="公司优劣势">
          {{ details.good }}
        </el-form-item>
      </el-form>
    </customDialog>
  </div>
</template>

<script>
/* 企业入驻列表 */
import { getCompanyInList } from "@/api/home.js";
import { getDict } from "@/api/common.js";
import local from "@/utils/local.js";

export default {
  data() {
    return {
      form: { auditStatus: "", pageNum: 1, pageSize: 50, wxUserid: "" },
      statusOptions: [],
      tableData: [],
      total: 0,
      detailShow: false,
      details: {},
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      const params = {
        ...this.form,
        wxUserid: local.get("userInfo").id,
      };
      let res = await getCompanyInList(params);
      if (res.code === 0) {
        this.tableData = res.data.records;
        this.total = res.data.total;
      }
    },
    // 获取选项数据
    async getOptions() {
      const statusOptions = await getDict({ source: "distributionFlag" });
      this.statusOptions = statusOptions;
    },

    /* 操作 */
    // 新增
    add() {
      this.$router.push({ path: "/settlement/add" });
    },
    // 查看详情
    showDetail(item) {
      this.detailShow = true;
      this.details = item;
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
  },

  created() {
    this.getOptions();
    this.getData();
  },
};
</script>

<style lang="less" scoped>
.settlement_list {
  background-color: #fff;
  padding: 20px;
}
::v-deep .avatar {
  // width: 300px;
  height: 180px;
  display: block;
}
</style>
