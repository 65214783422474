<template>
  <div class="container performance" v-loading="loading">
    <div class="top">
      <div class="name">{{ details.name }}</div>

      <div class="tag_style cursorP" @click="toCompanyDetail">查看企业详情</div>
    </div>

    <div class="table">
      <div class="table_cell">
        <div class="label">中标单位</div>
        <div class="value">
          {{ details.companyName | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">建设单位</div>
        <div class="value">
          {{ details.owner }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目所在地</div>
        <div class="value">
          {{ details.place | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目编号</div>
        <div class="value">
          {{ details.nameCode | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同金额</div>
        <div class="value">
          {{ details.amount | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">结算金额</div>
        <div class="value">
          {{ details.settlement | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">开工日期</div>
        <div class="value">
          {{ details.startTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">完工日期</div>
        <div class="value">
          {{ details.endTime | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同工期</div>
        <div class="value">
          {{ details.duration }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">工程等级</div>
        <div class="value">
          {{ details.grade | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">工程级别</div>
        <div class="value">
          {{ details.level | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">工程状态</div>
        <div class="value">
          {{ details.state | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">关键指标</div>
        <div class="value">
          {{ details.index | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">项目负责人</div>
        <div class="value">
          {{ details.projectLeader | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">技术负责人</div>
        <div class="value">
          {{ details.technicalDirector | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">主要参与人员</div>
        <div class="value">
          {{ details.staff | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">数据来源</div>
        <div class="value">
          {{ details.sourceCode | noVoid }}
        </div>
      </div>

      <div class="table_cell">
        <div class="label">合同主要内容</div>
        <div class="value">
          {{ details.content | noVoid }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* 水利专查业绩详情 */
import { getWaterPerformanceDetails } from "@/api/waterConservancy.js";

export default {
  data() {
    return {
      id: "", // id
      sourceCode: "", // 来源编码
      details: {}, // 详情
      loading: false, // 加载动画
    };
  },

  methods: {
    /* 获取数据 */
    // 获取详情
    async getData() {
      this.loading = true;
      const params = {
        id: this.id,
        sourceCode: this.sourceCode,
      };
      const { code, data } = await getWaterPerformanceDetails(params);
      if (code === 0) {
        data.sourceCode =
          data.sourceCode == "CREDIT"
            ? "全国水利建设市场信用信息平台"
            : "全国水利建设市场监管平台";
        this.details = data;
        this.loading = false;
      }
    },

    /* 操作 */
    // 跳转到网页
    linkToUrl(url) {
      if (url) {
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.target = "_blank";
        document.body.appendChild(a);
        a.click();
      } else {
        this.$message.warning("暂无跳转地址");
      }
    },
    // 跳转到企业详情页面
    toCompanyDetail() {
      let id = this.details.companyId;
      if (id) {
        this.$router.push({
          name: "companyDetails",
          params: { name: "企业业绩" },
          query: {
            id,
          },
        });
      } else {
        this.$message.warning("暂无企业信息！");
      }
    },

    /* 初始化 */
    __init__() {
      this.id = this.$route.params.id;
      this.sourceCode = this.$route.query.sourceCode;
      this.getData();
    },
  },

  created() {
    this.__init__(); // 初始化
  },

  activated() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.performance {
  padding: 20px;
  background-color: #fff;

  .top {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .name {
      font-size: 20px;
      font-weight: bold;
      color: #333;
    }
  }

  .table {
    .table_cell {
      display: flex;
      align-items: stretch;
      border: 1px solid #ecf1ff;
      border-bottom: none;
      border-right: none;
      font-size: 14px;

      &:last-child {
        border-bottom: 1px solid #ecf1ff;
      }

      .label {
        padding: 15px;
        min-width: 200px;
        text-align: center;
        color: #666;
        display: flex;
        align-items: center;
        background-color: #f5faff;
      }

      .value {
        display: flex;
        align-items: center;
        padding: 15px;
        flex: 1;
        color: #666;
        border-right: 1px solid #ecf1ff;
        border-left: 1px solid #ecf1ff;
        line-height: 1.5;
      }
    }
  }
}
</style>
