<template>
  <div class="recent_info">
    <div class="container">
      <div class="top_wrapper">
        <div class="tabs">
          <div
            :class="['tab_item', index == tabsActiveIndex ? 'active' : '']"
            v-for="(item, index) in tabsData"
            :key="item.name"
            @click="handleTabChange(index)"
          >
            {{ item.name }}
          </div>
        </div>

        <!-- <div class="right_wrapper">
          <div class="add">
            <img
              class="icon_add"
              src="https://www.zizhihelp.com/upload/static/web/dingwei.png"
              alt=""
            />
            <span>乐山</span>
          </div>

          <div class="change_btn">切换城市</div>
        </div> -->
      </div>

      <div class="list" v-loading="loading">
        <div class="list_item" v-for="item in list" :key="item.id">
          <div v-if="tabsActiveIndex == 0">
            <div class="name" @click="toDetail(item.recentResultId, 'result')">
              {{ item.recentResultName }}
            </div>

            <div class="bottom_wrapper">
              <div class="tags">
                <div
                  class="tag tag0 tag_ellipsis tag_project tag_link"
                  @click="toDetail(item.id, 'company')"
                >
                  中标公司：{{ item.name }}
                </div>
                <!-- <div class="tag">地址：{{ item.place }}</div> -->
              </div>

              <div class="time">
                中标时间：{{ item.recentResultTime || "--" }}
              </div>
            </div>
          </div>

          <div v-if="tabsActiveIndex == 1">
            <div class="name" @click="toDetail(item.id, 'result')">
              {{ item.name }}
            </div>

            <div class="bottom_wrapper">
              <div class="tags">
                <div
                  class="tag tag0 tag_link"
                  @click="
                    toDetail(item.winnerId, 'person', item.manager, item.time)
                  "
                >
                  项目经理：{{ item.manager }}
                </div>
                <div
                  class="tag tag_ellipsis tag_link"
                  @click="toDetail(item.winnerId, 'company')"
                >
                  中标公司：{{ item.winner }}
                </div>
              </div>

              <div class="time">中标时间：{{ item.time || "--" }}</div>
            </div>
          </div>

          <div v-if="tabsActiveIndex == 2">
            <div class="name" @click="toDetail(item.id, 'bids')">
              {{ item.name }}
            </div>

            <div class="bottom_wrapper">
              <div class="tags">
                <div class="tag tag0">招标金额：{{ item.money }}</div>
                <div class="tag tag_ellipsis">地址：{{ item.place }}</div>
              </div>

              <div class="time">发布时间：{{ item.time || "--" }}</div>
            </div>
          </div>
        </div>

        <el-empty description="暂无数据" v-if="list.length == 0"></el-empty>
      </div>
    </div>
  </div>
</template>

<script>
/* 首页-最新信息 */
// 引入ajax函数
import { searchRecent } from "@/api/home";

export default {
  data() {
    return {
      tabsData: [
        { name: "最近中标项目", list: [] },
        { name: "项目经理中标", list: [] },
        { name: "最新招标项目", list: [] },
      ],
      tabsActiveIndex: 0, // tab的激活索引
      list: [],
      // recentResultDtoList: [], // 最近中标公司
      // recentManagerList: [], // 最新中标项目经理
      // noticeDtoList: [], // 最新中标
      form: {
        place: "",
      },
      loading: false,
    };
  },

  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;
      let res = await searchRecent(this.form);
      this.tabsData[0].list = this.handleTime(res.recentResultDtoList);
      this.tabsData[1].list = this.handleAmount(
        res.recentManagerList,
        "mananger"
      );
      this.tabsData[2].list = this.handleAmount(res.noticeDtoList);
      this.list = this.tabsData[0].list;
      this.loading = false;
    },
    // 处理金额
    handleAmount(arr, flag) {
      arr.forEach((v) => {
        if (v.amtUnit == "元") {
          v.amount = (Number(v.amount) / 10000).toFixed(2);
        } else {
          v.amount = Number(v.amount).toFixed(2);
        }
        v.amtUnit = "万元";
        if (v.amtUnit == "-1" || v.amtUnit == "%" || !v.amtUnit) {
          v.amount = "-";
          v.amtUnit = "-";
        }
        v["money"] = v.amount + v.amtUnit;
        if (flag == "manager") {
          v["projectName"] = v.name;
          v.name = v.manager;
          v["companyId"] = v.winnerId;
        }
      });
      return arr;
    },
    // 处理时间
    handleTime(arr) {
      arr.forEach((v) => {
        if (v.recentResultTime.includes(" ")) {
          let time = v.recentResultTime.split(" ");
          v.recentResultTime = time[0];
        }
      });
      return arr;
    },

    /* 操作 */
    // 跳转到详情页面
    toDetail(id, flag, name, time) {
      if (flag == "company") {
        this.$router.push({
          name: "companyDetails",
          params: { name: "资质等级" },
          query: { id },
        });
      } else if (flag == "result") {
        this.$router.push({
          name: "performanceDetail",
          params: { id },
        });
      } else if (flag == "person") {
        this.$router.push({
          name: "personDetails",
          params: { id },
          query: { name, time },
        });
      } else if (flag == "bids") {
        this.$router.push({ name: "bidsDetails", params: { id } });
      }
    },
    // tab的点击事件
    handleTabChange(index) {
      this.tabsActiveIndex = index;
      this.list = this.tabsData[index].list;
    },

    // 初始化
    __init__() {
      this.getData();
    },
  },

  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.recent_info {
  padding: 40px;
  background-color: #fff;
  font-size: 16px;

  .top_wrapper {
    display: flex;
    align-content: center;
    justify-content: space-between;
    border-bottom: 1px solid #efefef;
    color: #666666;

    .tabs {
      display: flex;
      align-items: center;

      .tab_item {
        line-height: 28px;
        margin-right: 36px;
        padding: 10px 0px;
        cursor: pointer;
      }

      .active,
      .tab_item:hover {
        color: #333333;
        border-bottom: 2px solid #1d89e3;
      }
    }

    .right_wrapper {
      display: flex;
      align-items: center;

      .add {
        display: flex;
        align-items: center;
        .icon_add {
          width: 18px;
          height: 20px;
          margin-right: 8px;
        }
      }

      .change_btn {
        color: #333;
        line-height: 28px;
        padding: 2px 15px;
        border: 1px solid #999999;
        border-radius: 4px;
        margin-left: 12px;
        cursor: pointer;
      }
    }
  }

  .list {
    display: flex;
    flex-wrap: wrap;
    border-bottom: 1px solid #efefef;
    min-height: 300px;

    .list_item {
      width: calc((100% - 82px) / 2);
      margin-right: 82px;
      padding: 40px 0px;
      border-top: 1px dashed #cacaca;
      cursor: pointer;

      .name {
        color: #333;
        // font-size: 18px;
        font-weight: 600;
        margin-bottom: 20px;
        overflow: hidden; // 溢出隐藏
        text-overflow: ellipsis; // 溢出用省略号显示
        display: -webkit-box; // 作为弹性伸缩盒子模型显示。
        -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
        -webkit-line-clamp: 1; // 显示的行数
      }

      .bottom_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .tags {
          display: flex;
          align-items: center;

          .tag {
            font-size: 14px;
            color: #666666;
            padding: 2px 6px;
            background-color: #efefef;
            border-radius: 4px;
            margin-right: 10px;
            line-height: 22px;
          }

          .tag_link:hover {
            text-decoration: underline;
          }

          .tag0 {
            color: #1d89e3;
            background-color: #d7edfb;
          }

          .tag_ellipsis {
            max-width: 205px;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }

          .tag_project {
            max-width: 380px;
          }
        }

        .time {
          color: #999999;
          // font-size: 16px;
        }
      }

      &:nth-child(2n) {
        margin-right: 0px;
      }

      &:nth-child(1),
      &:nth-child(2) {
        border-top: none;
      }

      &:hover {
        .name {
          color: #1d89e3;
        }
      }
    }
  }
}
</style>
