<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目地区">
            <el-cascader
              v-model="form.location"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="发布时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="建设单位">
            <el-input
              v-model="form.owner"
              placeholder="请输入建设单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="代理单位">
            <el-input
              v-model="form.agent"
              placeholder="请输入招标代理单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <div v-if="showmore"> -->
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="项目名称">
            <el-input
              v-model="form.name"
              placeholder="请输入关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目分类">
            <el-select
              style="width: 100%"
              v-model="form.projectSort"
              placeholder="请选择项目分类"
              clearable
            >
              <el-option
                v-for="item in projectSortOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="招标方式">
            <el-select
              style="width: 100%"
              v-model="form.noticeType"
              placeholder="请选择招标方式"
              clearable
            >
              <el-option
                v-for="item in noticeTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="公告类型">
            <el-select
              style="width: 100%"
              v-model="form.articleType"
              placeholder="请选择公告类型"
              clearable
            >
              <el-option
                v-for="item in articleTypeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="招标金额">
            <div class="flex alc">
              <el-input
                v-model.number="form.amountMin"
                placeholder="请输入最小值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>

              <span class="ml10 mr10">至</span>

              <el-input
                v-model.number="form.amountMax"
                placeholder="请输入最大值"
                clearable
              >
                <template slot="append">万</template>
              </el-input>
            </div>
          </el-form-item>
        </el-col>
      </el-row>

      <el-form-item label="资质要求" style="margin-bottom: 0px">
        <el-row
          class="mb22"
          v-for="(item, index) in certStandard"
          :key="'project' + index"
        >
          <el-col class="mr20" :span="11">
            <el-cascader
              v-model="item.value"
              :options="certStandardOptions"
              filterable
              :props="{
                value: 'allDesc',
                label: 'desc',
                children: 'children',
                emitPath: false,
              }"
              clearable
            ></el-cascader>
          </el-col>

          <el-col :span="2" class="tac">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addCertStandard"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delCertStandard(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- </div> -->

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>

          <!-- <el-button @click="showmore = !showmore">
            {{ this.showmore ? "收起" : "更多" }}
          </el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          条招标信息
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="flex alc jcsb mb20">
            <div class="name link" @click="toDetail(item.id)">
              {{ item.name | noVoid }}
            </div>

            <div class="c666">
              发布时间：
              {{ item.time | noVoid }}
            </div>
          </div>

          <div class="desc">
            <div
              class="desc_item company"
              :title="`建设单位：${
                item.owner && item.owner != '-1' ? item.owner : '--'
              }`"
            >
              建设单位：
              <span class="right_content">{{ item.owner | noVoid }}</span>
            </div>

            <div
              class="desc_item"
              :title="`项目地区：${
                item.place && item.place != '-1' ? item.place : '--'
              }`"
            >
              项目地区：
              <span class="right_content">{{ item.place | noVoid }}</span>
            </div>

            <div
              class="desc_item"
              :title="`招标代理：${
                item.agent && item.agent != '-1' ? item.agent : '--'
              }`"
            >
              招标代理：
              <span class="right_content">{{ item.agent | noVoid }}</span>
            </div>

            <div
              class="desc_item"
              :title="`金额（万元）：${
                item.amount && item.amount != '-1' ? item.amount : '--'
              }`"
            >
              金额（万元）：
              <span class="right_content">{{ item.amount }}</span>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查招标 */
// 引入ajax函数
import { searchNoticeListByDto } from "@/api/itemizedQuery";
import { getDict, sourceTree } from "@/api/common";
import area from "@/utils/area"; // 引入区域json
import queryTypeTips from "../components/queryTypeTips.vue";

export default {
  components: {
    queryTypeTips,
  },

  // 数据
  data() {
    return {
      form: {
        location: [], // 地区
        timeMin: "", // 发布时间-最小
        timeMax: "", // 发布时间-最大
        owner: "", // 建设单位
        agent: "", // 代理单位
        name: "", // 项目名称
        projectSort: "", // 项目分类
        noticeType: "", // 招标方式
        articleType: "", // 公告类型
        amountMin: "", // 招标金额-最小
        amountMax: "", // 招标金额-最大
        certStandard: "", // 资质要求
        projectType: "", // 项目类型-暂时不做
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      },
      time: [], // 时间
      projectSortOptions: [], // 项目分类下拉数据
      noticeTypeOptions: [], // 招标方式下拉数据
      articleTypeOptions: [], // 公告类型下拉数据
      certStandard: [{ value: "" }], // 资质要求
      certStandardOptions: [], // 资质要求下拉数据
      areaOptions: [], // 地区选项数据
      queryTypeOptions: [], // 检索模式
      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },
      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const location = this.form.location.filter((v) => v != "").join("-");
      let timeMin = "";
      let timeMax = "";
      if (this.time.length > 0) {
        timeMin = this.time[0];
        timeMax = this.time[1];
      }
      const certStandard = this.certStandard
        .filter((v) => v.value != "")
        .map((v) => v.value)
        .join(",");

      const params = {
        ...this.form,
        location,
        timeMin,
        timeMax,
        certStandard,
        total: this.total,
      };

      const res = await searchNoticeListByDto(params);
      res.records.forEach((v) => {
        if (v.amtUnit == "%" || !v.amtUnit) {
          v.amount = "--";
        } else if (v.amtUnit == "元") {
          v.amount = (Number(v.amount) / 10000).toFixed(2);
        } else {
          v.amount = Number(v.amount).toFixed(2);
        }
      });
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 项目分类
      const projectSortOptions = await getDict({ source: "projectSort" });
      this.projectSortOptions = projectSortOptions;
      // 招标方式
      const noticeTypeOptions = await getDict({ source: "noticeType" });
      this.noticeTypeOptions = noticeTypeOptions;
      // 公告类型
      const articleTypeOptions = await getDict({ source: "articleType" });
      this.articleTypeOptions = articleTypeOptions;
      // 资质要求
      const certStandardOptions = await sourceTree({ source: "certType" });
      this.certStandardOptions = certStandardOptions;
      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({ name: "bidsDetails", params: { id } });
    },
    // 添加资质要求
    addCertStandard() {
      if (this.certStandard.length < 5) {
        this.certStandard.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除资质要求
    delCertStandard(index) {
      this.certStandard.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        location: [], // 地区
        timeMin: "", // 发布时间-最小
        timeMax: "", // 发布时间-最大
        owner: "", // 建设单位
        agent: "", // 代理单位
        name: "", // 项目名称
        projectSort: "", // 项目分类
        noticeType: "", // 招标方式
        articleType: "", // 公告类型
        amountMin: "", // 招标金额-最小
        amountMax: "", // 招标金额-最大
        certStandard: "", // 资质要求
        projectType: "", // 项目类型-暂时不做
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
      this.certStandard = [{ value: "" }];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },
    
    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查招标") {
        this.form.name = name;
      }

      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  activated() {
    this.__init__();
  },

  // 生命周期-创建完毕
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    font-size: 20px;
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        cursor: pointer;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
