<template>
  <div class="query_wrapper container">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="公路系统">
            <el-select
              style="width: 100%"
              v-model="form.source"
              placeholder="请选择公路系统"
              @change="handleSourceChange"
            >
              <el-option
                v-for="item in waterSourceListOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩地区">
            <el-cascader
              v-model="form.place"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩类型">
            <el-select
              style="width: 100%"
              v-model="form.levelList"
              placeholder="请选择业绩类型"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringGradeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="项目类型">
            <el-select
              style="width: 100%"
              v-model="form.gradeList"
              placeholder="请选择项目类型"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringLevelOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="承包方式">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择承包方式"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="建设状态">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择建设状态"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="中标企业">
            <el-input
              v-model.trim="form.companyName"
              placeholder="请输入中标企业关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="技术等级">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择技术等级"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="时间类型">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择开工时间类型"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="开工时间">
            <div class="flex alc">
              <el-date-picker
                class="flex1"
                v-model="startTime"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>

              <el-switch
                class="ml10"
                v-model="value1"
                active-text="时间为空"
                inactive-text=""
              >
              </el-switch>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="时间类型">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择完工时间类型"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="完工时间">
            <div class="flex alc">
              <el-date-picker
                class="flex1"
                v-model="endTime"
                type="daterange"
                align="right"
                unlink-panels
                range-separator="至"
                start-placeholder="开始日期"
                end-placeholder="结束日期"
                :picker-options="pickerOptions"
                value-format="yyyy-MM-dd"
              >
              </el-date-picker>

              <el-switch
                class="ml10"
                v-model="value1"
                active-text="时间为空"
                inactive-text=""
              >
              </el-switch>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="金额类型">
            <el-select
              style="width: 100%"
              v-model="form.stateList"
              placeholder="请选择金额类型"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterEngineeringStatusOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="业绩金额">
            <div class="flex alc">
              <div class="flex alc flex1">
                <el-input
                  v-model.number.trim="form.minAmount"
                  placeholder="最小值"
                  clearable
                >
                  <template slot="append">万</template>
                </el-input>

                <span class="ml10 mr10">至</span>

                <el-input
                  v-model.number.trim="form.maxAmount"
                  placeholder="最大值"
                  clearable
                >
                  <template slot="append">万</template>
                </el-input>
              </div>

              <el-switch
                class="ml10"
                v-model="value1"
                active-text="金额为空"
                inactive-text=""
              >
              </el-switch>
            </div>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="关键词" style="margin-bottom: 0px">
            <el-row
              class="mb22"
              v-for="(item, index) in projectNameList"
              :key="'project' + index"
            >
              <el-col :span="21">
                <el-input
                  placeholder="请输入关键词"
                  v-model.trim="item.keyword"
                  class="input-with-select"
                  clearable
                >
                  <el-select
                    style="width: 100px"
                    v-model="item.radio"
                    slot="prepend"
                    placeholder="请选择"
                    clearable
                  >
                    <el-option label="包含" value="包含"></el-option>
                    <el-option label="不包含" value="不包含"></el-option>
                  </el-select>
                </el-input>
              </el-col>

              <el-col :span="3" class="tac">
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  circle
                  plain
                  @click="addName"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delName(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="搜索范围">
            <el-select
              style="width: 100%"
              v-model="form.searchRangeList"
              placeholder="请选择搜索范围"
              clearable
              multiple
            >
              <el-option
                v-for="item in waterSearchRangeOptions"
                :key="item.name"
                :label="item.name"
                :value="item.value"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="技术参数" style="margin-bottom: 0px">
            <el-row
              class="mb22"
              v-for="(item, index) in certType"
              :key="'project' + index"
            >
              <el-col :span="21">
                <el-cascader
                  v-model="item.value"
                  :options="certTypeOptions"
                  filterable
                  :props="{
                    value: 'allDesc',
                    label: 'desc',
                    children: 'children',
                    emitPath: false,
                  }"
                  clearable
                ></el-cascader>
              </el-col>

              <el-col :span="3" class="tac">
                <el-button
                  v-if="index == 0"
                  type="primary"
                  icon="el-icon-plus"
                  plain
                  circle
                  @click="addCert"
                >
                </el-button>

                <el-button
                  v-else
                  @click="delCert(index)"
                  type="danger"
                  icon="el-icon-delete"
                  circle
                  plain
                ></el-button>
              </el-col>
            </el-row>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label=" " label-width="0px">
            <el-switch
              class="ml10"
              v-model="value1"
              active-text="包含资质到期"
              inactive-text=""
            >
            </el-switch>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>

          <!-- <el-button @click="showmore = !showmore">
            {{ this.showmore ? "收起" : "更多" }}
          </el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共为您找到
          <span class="highlight">{{ total }}</span>
          条业绩信息
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name link" @click="toDetail(item.id, item.sourceCode)">
            {{ item.name }}
          </div>

          <el-row class="desc mb20">
            <el-col
              :span="8"
              class="desc_item company"
              :title="
                item.companyName && item.companyName != '-1'
                  ? item.companyName
                  : '--'
              "
            >
              <div class="right_content">
                {{ item.companyName | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="'中标金额：' + item.amount"
            >
              <div class="left_text">中标金额：</div>
              <div class="right_content">
                {{ item.amount }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '地区：' +
                (item.place && item.place != '-1' ? item.place : '--')
              "
            >
              <div class="left_text">地区：</div>
              <div class="right_content">
                {{ item.place | noVoid }}
              </div>
            </el-col>
          </el-row>
          <el-row class="desc">
            <el-col
              :span="8"
              class="desc_item"
              :title="
                '项目经理：' +
                (item.projectLeader && item.projectLeader != '-1'
                  ? item.projectLeader
                  : '--')
              "
            >
              <div class="left_text">项目经理：</div>
              <div class="right_content">
                {{ item.projectLeader | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '建设单位：' +
                (item.owner && item.owner != '-1' ? item.owner : '--')
              "
            >
              <div class="left_text">建设单位：</div>
              <div class="right_content company">
                {{ item.owner | noVoid }}
              </div>
            </el-col>

            <el-col
              :span="8"
              class="desc_item"
              :title="
                '中标时间：' +
                (item.startTime && item.startTime != '-1'
                  ? item.startTime
                  : '--')
              "
            >
              <div class="left_text">中标时间：</div>
              <div class="right_content">
                {{ item.startTime | noVoid }}
              </div>
            </el-col>
          </el-row>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查业绩 */
// 引入ajax函数
import { queryWaterPerformance } from "@/api/waterConservancy.js";
import { getDict } from "@/api/common";
// 引入区域json
import area from "@/utils/area";
import queryTypeTips from "@/views/itemizedQuery/components/queryTypeTips.vue";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        source: "全国水利建设市场监管平台", // 业绩/数据来来源
        place: [], // 项目所在地
        levelList: [], // 工程等别
        gradeList: [], // 工程级别
        stateList: [], // 工程状态
        minAmount: "", // 项目金额最小值
        maxAmount: "", // 项目金额最大值
        startBeginAt: "", // 开工日期-开始时间
        startEndAt: "", // 开工日期-结束时间
        completeBeginAt: "", // 完工日期-开始时间
        completeEndAt: "", // 完工日期-结束时间
        companyName: "", // 企业名称
        projectLeader: "", // 项目经理/项目负责人
        contKeywordList: [], // 包含的关键词
        exclKeywordList: [], // 不包含的关键词
        searchRangeList: [], // 搜索范围
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      },

      areaOptions: [], // 地区选项数据
      waterSourceListOptions: [], // 业绩来源数据
      waterEngineeringGradeOptions: [], // 业绩条件-工程等别数据
      waterEngineeringLevelOptions: [], // 业绩条件-工程级别数据
      waterEngineeringStatusOptions: [], // 业绩条件-工程状态数据
      waterPerformanceTypeOptions: [], // 业绩条件-业绩类型数据
      waterEngineeringTypeOptions: [], // 业绩条件-工程类型数据
      waterSearchRangeOptions: [], // 关键词-搜索范围数据
      waterSearchRangeOriginOptions: [], // 关键词-搜索范围数据(初始数据)
      waterSearchRange2Options: [], // 关键词-搜索范围数据(全国水利建设市场监管平台)
      queryTypeOptions: [], // 检索模式

      startTime: [], // 开工日期
      endTime: [], // 完工日期
      // 项目名称
      projectNameList: [
        {
          radio: "",
          keyword: "",
        },
      ],

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项

      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      value1: false,
      certTypeOptions: [], // 资质分类选项数据
      certType: [{ value: "" }], // 资质分类
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      // this.loading = true;

      const place = this.form.place.filter((v) => v != "").join("-");

      let startBeginAt = ""; // 开工日期-开始时间
      let startEndAt = ""; // 开工日期-结束时间
      let completeBeginAt = ""; // 完工日期-开始时间
      let completeEndAt = ""; // 完工日期-结束时间
      if (this.startTime.length > 0) {
        startBeginAt = this.startTime[0];
        startEndAt = this.startTime[1];
      }
      if (this.endTime.length > 0) {
        completeBeginAt = this.endTime[0];
        completeEndAt = this.endTime[1];
      }
      const contKeywordList = this.projectNameList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword);
      const exclKeywordList = this.projectNameList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword);

      const params = {
        ...this.form,
        place,
        startBeginAt, // 开工日期-开始时间
        startEndAt, // 开工日期-结束时间
        completeBeginAt, // 完工日期-开始时间
        completeEndAt, // 完工日期-结束时间
        contKeywordList,
        exclKeywordList,
        total: this.total,
      };

      const { code, data } = await queryWaterPerformance(params);
      if (code !== 0) return;
      this.list = data.records;
      this.total = data.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 业绩来源
      const waterSourceListOptions = await getDict({
        source: "waterSourceList",
      });
      this.waterSourceListOptions = waterSourceListOptions;

      // 关键词-搜索范围
      const waterSearchRangeOriginOptions = await getDict({
        source: "waterSearchRange",
      });
      this.waterSearchRangeOriginOptions = waterSearchRangeOriginOptions;

      // 关键词-搜索范围(全国水利建设市场监管平台)
      const waterSearchRangeOptions = await getDict({
        source: "waterSearchRange2",
      });
      this.waterSearchRangeOptions = this.waterSearchRange2Options =
        waterSearchRangeOptions;

      // 业绩条件-业绩类型
      const waterPerformanceTypeOptions = await getDict({
        source: "waterPerformanceType",
      });
      this.waterPerformanceTypeOptions = waterPerformanceTypeOptions;

      // 业绩条件-工程类型
      const waterEngineeringTypeOptions = await getDict({
        source: "waterEngineeringType",
      });
      this.waterEngineeringTypeOptions = waterEngineeringTypeOptions;

      // 业绩条件-工程等别
      const waterEngineeringGradeOptions = await getDict({
        source: "waterEngineeringGrade",
      });
      this.waterEngineeringGradeOptions = waterEngineeringGradeOptions;

      // 业绩条件-工程级别
      const waterEngineeringLevelOptions = await getDict({
        source: "waterEngineeringLevel",
      });
      this.waterEngineeringLevelOptions = waterEngineeringLevelOptions;

      // 业绩条件-工程状态
      const waterEngineeringStatusOptions = await getDict({
        source: "waterEngineeringStatus",
      });
      this.waterEngineeringStatusOptions = waterEngineeringStatusOptions;

      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions;
    },

    /* 操作 */
    // 添加资质分类
    addCert() {
      if (this.certType.length < 5) {
        this.certType.push({
          value: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除资质分类
    delCert(index) {
      this.certType.splice(index, 1);
    },

    // 业绩来源change事件
    handleSourceChange(val) {
      if (val == "全国水利建设市场监管平台") {
        this.waterSearchRangeOptions = this.waterSearchRange2Options;
      } else {
        this.waterSearchRangeOptions = this.waterSearchRangeOriginOptions;
      }
    },
    // 跳转详情
    toDetail(id, sourceCode) {
      this.$router.push({
        name: "waterConservancyDetails",
        params: { id },
        query: { sourceCode },
      });
    },
    // 添加项目名称
    addName() {
      if (this.projectNameList.length < 5) {
        this.projectNameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.projectNameList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        source: "全国水利建设市场监管平台", // 业绩/数据来来源
        searchRangeList: [], // 搜索范围
        contKeywordList: [], // 包含的关键词
        exclKeywordList: [], // 不包含的关键词
        place: [], // 项目所在地
        levelList: [], // 工程等别
        gradeList: [], // 工程级别
        stateList: [], // 工程状态
        startBeginAt: "", // 开工日期-开始时间
        startEndAt: "", // 开工日期-结束时间
        completeBeginAt: "", // 完工日期-开始时间
        completeEndAt: "", // 完工日期-结束时间
        minAmount: "", // 项目金额最小值
        maxAmount: "", // 项目金额最大值
        companyName: "", // 企业名称
        projectLeader: "", // 项目经理/项目负责人
        queryType: "like", // 检索模式
        pageNum: 1,
        pageSize: 20,
      };
      this.startTime = []; // 开工日期
      this.endTime = []; // 完工日期
      this.projectNameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    query() {
      this.form.pageNum = 1;
      this.total = 0;
      // this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查业绩") {
        this.projectNameList[0] = {
          radio: "包含",
          keyword: name,
        };
      }
      // this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  async activated() {
    this.__init__();
  },

  // 生命周期-创建完成
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .desc {
        // display: flex;
        // align-items: center;
        color: #999;

        .desc_item {
          // flex: 2;
          display: flex;
          align-items: center;
          // margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }

  /deep/.el-pagination {
    text-align: right;
    padding: 20px 30px;
  }

  /deep/.el-tabs__header {
    background-color: #fff;
    padding: 20px;
  }

  /deep/.el-form {
    background-color: #fff;
    margin-bottom: 30px;
    padding: 30px;
    padding-left: 20px;
  }

  /deep/.el-cascader,
  /deep/.el-date-editor {
    width: 100%;
  }

  /deep/.list {
    font-size: 16px;
    background-color: #fff;
    .desc {
      font-size: 14px;
    }
  }

  /deep/.total {
    padding: 20px 30px;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  /deep/.el-date-editor .el-range-separator {
    width: 6%;
  }
}
</style>
