<template>
  <div class="query_wrapper">
    <el-form ref="form" :model="form" label-width="105px">
      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="评价所在地">
            <el-cascader
              v-model="form.place"
              :options="areaOptions"
              filterable
              :props="{ value: 'value', label: 'name', children: 'city' }"
              clearable
            ></el-cascader>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="评价时间">
            <el-date-picker
              v-model="time"
              type="daterange"
              align="right"
              unlink-panels
              range-separator="至"
              start-placeholder="开始日期"
              end-placeholder="结束日期"
              :picker-options="pickerOptions"
              value-format="yyyy-MM-dd"
            >
            </el-date-picker>
          </el-form-item>
        </el-col>
      </el-row>

      <el-row :gutter="20">
        <el-col :span="12">
          <el-form-item label="获评企业">
            <el-input
              v-model.trim="form.company"
              placeholder="请输入获评企业关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>

        <el-col :span="12">
          <el-form-item label="给予单位">
            <el-input
              v-model.trim="form.announcedUnits"
              placeholder="请输入给予单位关键词"
              clearable
            ></el-input>
          </el-form-item>
        </el-col>
      </el-row>

      <!-- <div v-if="showmore"> -->
      <el-form-item label="评价名称" style="margin-bottom: 0px">
        <el-row
          class="mb22"
          v-for="(item, index) in nameList"
          :key="'project' + index"
        >
          <el-col class="mr20" :span="11">
            <el-input
              placeholder="请输入关键词"
              v-model.trim="item.keyword"
              class="input-with-select"
              clearable
            >
              <el-select
                style="width: 130px"
                v-model="item.radio"
                slot="prepend"
                placeholder="请选择"
                clearable
              >
                <el-option label="包含" value="包含"></el-option>
                <el-option label="不包含" value="不包含"></el-option>
              </el-select>
            </el-input>
          </el-col>

          <el-col :span="2" class="tac">
            <el-button
              v-if="index == 0"
              type="primary"
              icon="el-icon-plus"
              plain
              @click="addName"
            >
              增加
            </el-button>

            <el-button
              v-else
              @click="delName(index)"
              type="danger"
              icon="el-icon-delete"
              circle
              plain
            ></el-button>
          </el-col>
        </el-row>
      </el-form-item>
      <!-- </div> -->

      <el-row>
        <el-col :span="24" class="tac">
          <el-button @click="reset" icon="el-icon-refresh-right">
            重置
          </el-button>

          <el-button type="primary" @click="query" icon="el-icon-search">
            查询
          </el-button>

          <!-- <el-button @click="showmore = !showmore">
            {{ this.showmore ? "收起" : "更多" }}
          </el-button> -->
        </el-col>
      </el-row>
    </el-form>

    <div
      class="list"
      v-loading="loading"
      element-loading-text="拼命加载中"
      element-loading-spinner="el-icon-loading"
      element-loading-background="rgba(0, 0, 0, 0.8)"
    >
      <div class="total">
        <div>
          共有评价信息
          <span class="highlight">{{ total }}</span>
          条
        </div>

        <div class="flex alc">
          <div class="fz14">查询模式：</div>
          <el-radio-group v-model="form.queryType" @change="query">
            <el-radio
              :label="item.value"
              v-for="item in queryTypeOptions"
              :key="item.value"
            >
              {{ item.name }}
            </el-radio>
          </el-radio-group>

          <queryTypeTips />
        </div>
      </div>

      <div
        :class="['list_item', index == list.length - 1 ? 'bb1' : '']"
        v-for="(item, index) in list"
      >
        <div class="index">{{ index + 1 }}</div>

        <div class="flex1">
          <div class="name link" @click="toDetail(item.id)">
            {{ item.name }}
          </div>

          <div class="desc">
            <div
              class="desc_item company"
              :title="`公司名称：${
                item.company && item.company != '-1' ? item.company : '--'
              }`"
            >
              <div class="right_content">
                {{ item.company | noVoid }}
              </div>
            </div>

            <div
              class="desc_item"
              :title="
                '信息来源：' +
                (item.announcedUnits && item.announcedUnits != '-1'
                  ? item.announcedUnits
                  : '--')
              "
            >
              <div class="left_text">信息来源：</div>
              <div class="right_content">
                {{ item.announcedUnits | noVoid }}
              </div>
            </div>

            <div
              class="desc_item"
              :title="
                '获评时间：' +
                (item.time && item.time != '-1'
                  ? item.time.split(' ')[0]
                  : '--')
              "
            >
              <div class="left_text">获评时间：</div>
              <div class="right_content">
                {{ item.time | timeFormat }}
              </div>
            </div>
          </div>
        </div>
      </div>

      <el-empty v-if="list.length == 0" description="暂无数据"></el-empty>

      <!-- 分页 -->
      <el-pagination
        @size-change="sizeChange"
        @current-change="currentChange"
        :current-page="form.pageNum"
        :page-sizes="[10, 20, 30, 40, 50, 100]"
        :page-size="form.pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
      >
      </el-pagination>
    </div>
  </div>
</template>

<script>
/* 分项查询-查评价 */
// 引入ajax函数
import { searchEvaluate } from "@/api/itemizedQuery";
import { getDict } from "@/api/common";
import area from "@/utils/area"; // 引入区域json
import queryTypeTips from "../components/queryTypeTips";

export default {
  components: { queryTypeTips },

  // 数据
  data() {
    return {
      form: {
        place: [], // 地区
        timeMin: "", // 获奖时间-最小值
        timeMax: "", // 获奖时间-最大值
        company: "", // 获奖企业
        announcedUnits: "", // 颁发单位
        honorType: "", // 数据字典：honorType
        grade: "", // 数据字典：honorGrade
        nameIncludeList: [], // 荣誉名称-包含
        nameExcludeList: [], // 荣誉名称-不包含
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      },
      areaOptions: [], // 地区选项数据
      time: [], // 时间
      honorTypeOptions: [], // 荣誉奖项下拉数据
      gradeOptions: [], // 荣誉奖项下拉数据
      queryTypeOptions: [], // 检索模式
      // 荣誉名称
      nameList: [
        {
          radio: "",
          keyword: "",
        },
      ],
      // 时间选择器快捷选项
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() > Date.now();
        },
        shortcuts: [
          {
            text: "最近一年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近三年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 3);
              picker.$emit("pick", [start, end]);
            },
          },
          {
            text: "最近五年",
            onClick(picker) {
              const end = new Date();
              const start = new Date();
              start.setTime(start.getTime() - 3600 * 1000 * 24 * 365 * 5);
              picker.$emit("pick", [start, end]);
            },
          },
        ],
      },

      list: [], // 列表
      total: 0, // 总数
      loading: false, // 加载状态
      showmore: false, // 更多查询项
    };
  },

  // 方法
  methods: {
    /* 获取数据 */
    // 获取列表数据
    async getData() {
      this.loading = true;

      const place = this.form.place.filter((v) => v != "").join("-");
      let timeMin = "";
      let timeMax = "";
      if (this.time.length > 0) {
        timeMin = this.time[0];
        timeMax = this.time[1];
      }
      const nameIncludeList = this.nameList
        .filter((v) => v.radio == "包含" && v.keyword)
        .map((v) => v.keyword);
      const nameExcludeList = this.nameList
        .filter((v) => v.radio == "不包含" && v.keyword)
        .map((v) => v.keyword);

      const params = {
        ...this.form,
        place,
        timeMin,
        timeMax,
        nameIncludeList,
        nameExcludeList,
        total: this.total,
      };

      const res = await searchEvaluate(params);
      this.list = res.records;
      this.total = res.total;
      this.loading = false;
    },
    // 获取下拉选项数据
    async getOptions() {
      // 荣誉奖项-类型
      const honorTypeOptions = await getDict({ source: "honorType" });
      this.honorTypeOptions = honorTypeOptions;
      // 荣誉奖项-等级
      const gradeOptions = await getDict({ source: "honorGrade" });
      this.gradeOptions = gradeOptions;
      // 检索模式
      const queryTypeOptions = await getDict({ source: "queryType" });
      this.queryTypeOptions = queryTypeOptions;
    },

    /* 操作 */
    // 跳转详情
    toDetail(id) {
      this.$router.push({ name: "evaluateDetails", params: { id } });
    },
    // 添加项目名称
    addName() {
      if (this.nameList.length < 5) {
        this.nameList.push({
          radio: "",
          keyword: "",
        });
      } else {
        this.$message({ message: "最多添加5条筛选条件~", type: "warning" });
      }
    },
    // 删除项目名称
    delName(index) {
      this.nameList.splice(index, 1);
    },
    // 当前显示页数改变时
    sizeChange(val) {
      this.form.pageSize = val;
      this.getData();
    },
    // 当前页改变时
    currentChange(val) {
      this.form.pageNum = val;
      this.getData();
    },
    // 重置
    reset() {
      this.form = {
        place: [], // 地区
        timeMin: "", // 获奖时间-最小值
        timeMax: "", // 获奖时间-最大值
        company: "", // 获奖企业
        announcedUnits: "", // 颁发单位
        honorType: "", // 数据字典：honorType
        grade: "", // 数据字典：honorGrade
        nameIncludeList: [], // 荣誉名称-包含
        nameExcludeList: [], // 荣誉名称-不包含
        queryType: "like", // 检索模式
        userId: "",
        pageNum: 1,
        pageSize: 20,
      };
      this.time = [];
      this.nameList = [
        {
          radio: "",
          keyword: "",
        },
      ];
    },
    // 查询
    async query() {
      this.form.pageNum = 1;
      this.total = 0;
      this.getData();
    },

    /* 初始化 */
    __init__() {
      this.areaOptions = area;
      let name = this.$route.query.name;
      let path = this.$route.params.name;
      if (name && path == "查评价") {
        this.nameList = [
          {
            radio: "包含",
            keyword: name,
          },
        ];
      }

      this.getData();
      this.getOptions();
    },
  },

  // 生命周期-实例激活状态
  async activated() {
    this.__init__();
  },
  
  // 生命周期-创建完毕
  created() {
    this.__init__();
  },
};
</script>

<style lang="less" scoped>
.query_wrapper {
  .input-with-select /deep/.el-input-group__prepend {
    background-color: #fff;
  }

  .list {
    background-color: #fff;

    .list_item {
      display: flex;
      align-items: baseline;
      padding: 30px;
      border-top: 1px dashed #efefef;

      .index {
        margin-right: 20px;
        color: #999999;
        width: 1rem;
        text-align: center;
      }

      .name {
        color: #333;
        margin-bottom: 20px;
        cursor: pointer;
      }

      .desc {
        display: flex;
        align-items: center;
        color: #999;

        .desc_item {
          flex: 1;
          display: flex;
          align-items: center;
          margin-right: 30px;
          .right_content {
            flex: 1;
            overflow: hidden; // 溢出隐藏
            text-overflow: ellipsis; // 溢出用省略号显示
            display: -webkit-box; // 作为弹性伸缩盒子模型显示。
            -webkit-box-orient: vertical; // 设置伸缩盒子的子元素排列方式：从上到下垂直排列
            -webkit-line-clamp: 1; // 显示的行数
          }
        }
      }

      .company {
        cursor: pointer;
        &:hover {
          color: #1d89e3;
          text-decoration: underline;
        }
      }

      &:hover {
        background-color: #f5f9ff !important;
      }

      &:nth-child(2) {
        border-top: none;
        border-top: 1px solid #efefef;
      }

      &:nth-child(2n-1) {
        background-color: #f9f9f9;
      }
    }
    .bb1 {
      border-bottom: 1px solid #efefef;
    }
  }
}
</style>
