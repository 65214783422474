<template>
  <div class="ml10">
    <el-popover placement="bottom" width="300" trigger="hover">
      <!-- <i class="el-icon-question cursorP" slot="reference"></i> -->
      <img
        class="queryType_icon_tips cursorP"
        slot="reference"
        src="https://www.zizhihelp.com/upload/static/icon_query.png"
      />

      <div class="queryType_tips">
        <div class="queryType_tips_title">
          <span>检索模式说明</span>
        </div>

        <div class="queryType_tips_content">
          [精准检索]检索结果完全匹配关键词,如检索
          <span class="cred">"四川秀丽山河工程"</span>
          ,检索结果一定完整包含
          <span class="cred">"四川秀丽山河工程"</span>
          ,但如果标题中有
          <span class="cred">"四川秀丽山河建设"</span>
          ,不会检索到。
        </div>

        <div class="queryType_tips_content">
          [模糊检索]如检索
          <span class="cred"> "四川秀丽山河工程管理有限公司" </span>
          ,我们先会帮您智能分词,然后再进行检索标题中有
          <span class="cred">"四川秀丽山河"</span>
          或者
          <span class="cred">"山河秀丽"</span>
          都会被检索到。
        </div>
      </div>
    </el-popover>
  </div>
</template>

<script>
export default {};
</script>

<style lang="less" scoped>
.el-icon-question {
  color: #999;
  margin-left: 10px;
}

.queryType_tips {
  color: #666666;
  font-size: 14px;
}
.queryType_tips_title {
  display: flex;
  align-items: center;
  font-size: 14px;
  margin-bottom: 10px;
}
.queryType_icon_tips {
  width: 16px;
  height: 16px;
  margin-right: 5px;
}
.queryType_tips_content {
  margin-bottom: 10px;
  line-height: 1.5;
}
.cred {
  color: red;
}
</style>
